import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.42 119.92">
    <path className="cow" fill="#ae0f0a" d="M.09,30.55c4.47,19.05,15.6,27.18,37.2,27.18a3.37,3.37,0,0,0,0-6.74h0C20.65,51,12,45.91,7.81,33.14h11c16,0,21.94,7.4,26,18.51A263,263,0,0,1,55,89.26a3.37,3.37,0,0,0,3.31,2.8h.58a3.37,3.37,0,0,0,2.72-3.92v0A268,268,0,0,0,51.08,49.36c-3.87-10.69-11-22.95-32.3-22.95H3.28C-.86,26.41.09,30.55.09,30.55Zm129.48,2.59h11C136.38,45.91,127.76,51,111.12,51a3.37,3.37,0,0,0,0,6.74c21.6,0,32.73-8.13,37.2-27.18,0,0,1-4.14-3.19-4.14H129.57c-21.26,0-28.43,12.26-32.3,23A270.57,270.57,0,0,0,86.78,88.18,3.38,3.38,0,0,0,89.57,92a5.61,5.61,0,0,0,.58,0,3.35,3.35,0,0,0,3.31-2.8,265.76,265.76,0,0,1,10.18-37.61C107.65,40.54,113.57,33.14,129.57,33.14ZM116.42,3.79A3.37,3.37,0,0,0,109.74,3,35.17,35.17,0,0,1,97,25.84C93.11,24,84.51,20.23,74.2,20.23a52.63,52.63,0,0,0-22.63,5.56A35.22,35.22,0,0,1,38.75,3a3.37,3.37,0,1,0-6.69.84A42,42,0,0,0,47.47,31.18c.07.05,2.31,1.92,3.59,1.92a5,5,0,0,0,2.31-.71C57,30.58,64.83,26.92,74.2,26.92s17.34,3.66,20.91,5.47a5.16,5.16,0,0,0,2.32.71c1.28,0,3.52-1.87,3.58-1.92A42,42,0,0,0,116.42,3.79Zm-66.25,103a12.8,12.8,0,0,0,3.66,9.07c1.86,1.88,5.27,4.11,11,4.11H83.57c5.73,0,9.14-2.23,11-4.11a12.72,12.72,0,0,0,3.66-9.07c0-6.73-4.7-11.81-10.93-11.81a3.37,3.37,0,0,0,0,6.74c3.07,0,4.19,3,4.19,5.07,0,3.12-2.08,6.45-7.92,6.45H64.82c-5.83,0-7.91-3.33-7.91-6.45,0-2,1.11-5.07,4.19-5.07a3.37,3.37,0,1,0,0-6.74h0c-6.23,0-10.93,5-10.93,11.77Z" />
  </svg>
)

const component = () => (
  <section className="footer">
    <div className="container">
      <StaticQuery
        query={graphql`
          query SettingsQuery {
            wordpressAcfOptions {
              options {
                columns {
                  left
                  right
                }
              }
            }
          }
        `}
        render={data => (
          <div className="row justify-content-center align-items-center">
            <div
              className="col-12 col-sm order-2 order-sm-1 left"
              dangerouslySetInnerHTML={{
                __html: data.wordpressAcfOptions.options.columns.left,
              }}
            />
            <div className="col-12 col-sm-2 col-lg-1 order-1 order-sm-2 logo">
              <Icon />
            </div>
            <div
              className="col-12 col-sm order-3 right"
              dangerouslySetInnerHTML={{
                __html: data.wordpressAcfOptions.options.columns.right,
              }}
            />
          </div>
        )}
      />
    </div>
  </section>
)

export default component
