import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Footer from "./Footer"

const TemplateWrapper = (props) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        wordpressSiteMetadata {
          name
        }
      }
    `}
    render={() => {
      return (
        <>
          <Helmet
            title={props.title === 'Framsidan'
              ? 'Närke Kött'
              : `${props.title} - Närke Kött`}
          />
          <div className={`wrap ${props.type}`}>
            {props.children}
            <Footer />
          </div>
        </>
      )
    }}
  />
)

export default TemplateWrapper
